.CharacterView .CharacterImage {
  width: 200px;
  height: 200px;
  margin-top: 0.5em;
  margin-left: 0.5em;
}

.CharacterView .CharacterImage img {
  max-width: 200px;
  max-height: 200px;
  border: solid lightgrey 1px;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.CharacterView .CharacterImage img:hover {
  border: solid rgb(70, 151, 226) 1px;
}

.CharacterView .CharacterImageUpload {
  cursor: pointer;
  width: 200px;
  height: 200px;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CharacterView .CharacterImageUpload:not(.ImageUploaded) {
  border: dashed rgba(211, 211, 211, 0.5) 2px;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.CharacterView .CharacterImageUpload:not(.ImageUploaded):hover {
  border: dashed rgb(70, 151, 226) 2px;
}

.CharacterView .CharacterImageUpload label {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
