.CharacterView {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadein 0.25s ease-out;
}

.CharacterView .MainInfo {
  width: 100%;
  display: flex;
  padding: 0.5em 0 0.5em 0.5em;
}

.CharacterView .MainInfo .MainInfoFields {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;
}

.CharacterView .MainInfo .MainInfoFields .MainInfoField {
  min-width: 200px;
  padding: 1em 1em 0 1em;
  display: flex;
}

.CharacterView .MainInfo .MainInfoFields .MainInfoField .ant-row {
  margin-bottom: 0px;
}

.CharacterView .MainInfo .MainInfoFields .MainInfoField .ant-form-item-label {
  width: 88px;
}

.CharacterView .Stats {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgb(19, 19, 19);
  margin-top: 1em;
}

.CharacterView .Stats .AbilityStats {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.CharacterView .Stats .AbilityStats .StatButton {
  margin: 0.5em;
  padding: 0;
  display: flex;
  height: auto;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.04) !important;
}

.CharacterView .Stats .AbilityStats .AbilityStat {
  max-width: 100px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CharacterView .Stats .AbilityStats .AbilityStat .AbilityStatRow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.CharacterView .Stats .AbilityStats .AbilityStat .AbilityStatName {
  margin-top: 1em;
}

.CharacterView .Stats .AbilityStats .AbilityStat .AbilityStatBigText {
  font-size: 1.5em;
  font-weight: bold;
}

.CharacterView .Stats .AbilityStats .AbilityStat .AbilityStatRow .AbilityStatCol {
  display: flex;
  flex-grow: 1;
  min-width: 42px;
  justify-content: center;
  align-items: center;
}

.CharacterView .Stats .AbilityStats .AbilityStat .AbilityStatRow .AbilityStatCol .ant-row {
  margin-bottom: 0;
}

.CharacterView .Stats .AbilityStats .AbilityStat .ant-input {
  text-align: center;
}

.CharacterView .Stats .MainStats {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.CharacterView .Stats .MainStats .StatButton {
  margin: 0.5em;
  padding: 0;
  display: flex;
  height: auto;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.04) !important;
}

.CharacterView .Stats .MainStats .StatButton.NotRollable {
  border-color: #434343;
  background: rgba(255, 255, 255, 0.04) !important;
  color: rgba(255, 255, 255, 0.85);
  cursor: auto;
}

.CharacterView .Stats .MainStats .StatButton.NotRollable::after {
  opacity: 0;
}

.CharacterView .Stats .MainStats .MainStat {
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: normal;
}

.CharacterView .Stats .MainStats .MainStat.MainStatNormal {
  max-width: 100px;
}

.CharacterView .Stats .MainStats .MainStat.MainStatNarrow {
  max-width: 80px;
}

.CharacterView .Stats .MainStats .MainStat.MainStatWide {
  max-width: 150px;
}

.CharacterView .Stats .MainStats .MainStat .MainStatRow {
  height: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.CharacterView .Stats .MainStats .MainStat .MainStatName {
  margin-top: 1em;
  text-align: center;
}

.CharacterView .Stats .MainStats .MainStat .MainStatRow .MainStatCol {
  display: flex;
  flex-grow: 1;
  min-width: 42px;
  justify-content: center;
  align-items: center;
}

.CharacterView .Stats .MainStats .MainStat .MainStatRow .MainStatColNarrow {
  display: flex;
  flex-grow: 1;
  min-width: 30px;
  justify-content: center;
  align-items: center;
}

.CharacterView .Stats .MainStats .MainStat .MainStatRow .MainStatCol .ant-row {
  margin-bottom: 0;
}

.CharacterView .Stats .MainStats .MainStat .ant-input {
  text-align: center;
}

.CharacterView .Stats .MainStats .MainStat .ant-input:disabled {
  background-color: rgba(0, 0, 0, 0);
  color: rgba(255, 255, 255, 0.85);
}

.CharacterView .RollStatButton {
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.CharacterView .Tabs {
  width: 100%;
  display: flex;
  flex-grow: 1;
}

.CharacterView .Tabs .TabsMenu {
  max-width: 220px;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
}

.CharacterView .Tabs .TabsMenuSpacer {
  width: 210px;
}

.CharacterView .Tabs .TabsMenu .ant-menu {
  border-top: 1px solid #303030;
  border-top-right-radius: 4px;
  margin-top: 0.5em;
}

.CharacterView .Tabs .TabsMenu .ant-menu-item {
  height: 3.5vh;
}

.CharacterView .Tabs .TabsMenu .ant-menu-item svg {
  font-size: 1.5em;
}

.CharacterView .Tabs .TabsContent {
  height: 100%;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.CharacterView .Tabs .TabsContentContainer {
  max-width: 62vw;
  height: 100%;
  min-height: 500px;
  flex-grow: 1;
  overflow: auto;
}

.CharacterView .ant-collapse-header {
  height: 32px;
  line-height: 16px !important;
}

.CharacterView .Biography {
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.CharacterView .Biography .BioShortFields {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;
}

.CharacterView .Biography .BioShortFields .BioShortField {
  min-width: 200px;
  padding: 1em 2em 0 2em;
  display: flex;
}

.CharacterView .Biography .BioShortFields .BioShortField .ant-row {
  margin-bottom: 0px;
}

.CharacterView .Biography .BioShortFields .BioShortField .ant-form-item-label {
  width: 88px;
}

.CharacterView .Biography .BioLongFields {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.CharacterView .Biography .BioLongFields .ant-form-item-label {
  width: 150px;
}

.CharacterView .Resources {
  padding: 1em;
}

.CharacterView .ResourcesContent {
  padding-right: 6vw;
}

.CharacterView .Resources .Resource {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.CharacterView .Resources .Empty {
  width: 100%;
  padding: 1em;
  font-style: italic;
  text-align: center;
}

.CharacterView .Resources .ButtonWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CharacterView .Resources .NewButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  margin-top: 0.25em;
}

.CharacterView .Resources .NewButton svg {
  margin-right: 0.5em;
  font-size: 1.25em;
}

.CharacterView .Resources .Resource .ResourceName {
  width: 300px;
  margin-right: 1em;
}

.CharacterView .Resources .Resource .ResourceCurrent {
  width: 120px;
  margin-right: 1em;
}

.CharacterView .Resources .Resource .ResourceMax {
  width: 120px;
  margin-right: 1em;
}

.CharacterView .Resources .Resource .PlusMinus {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.CharacterView .Resources .Resource .ant-form-item-label {
  width: 60px;
}

.CharacterView .Resources .Resource .ant-row {
  margin-bottom: 1em;
}

.CharacterView .Currencies {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1.2em;
  margin-bottom: -1.8em;
}

.CharacterView .Currencies .ant-input-number {
  width: 120px;
}

.CharacterView .Proficiencies {
  display: flex;
  flex-direction: column;
  padding: 1em;
  padding-right: 6vw;
}

.CharacterView .Proficiencies .ant-form-item-label {
  width: 132px;
}

.CharacterView .Notes {
  padding: 1em;
  padding-right: 6vw;
}

.CharacterView .Notes .ant-form-item-label {
  width: 6vw;
}

.CharacterView .SpellStats {
  margin-top: -0.5em;
  padding: 1em 1em 0 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CharacterView .SpellStats .Label {
  margin-right: 0.25em;
  margin-left: 3em;
}

.CharacterView .SpellStats .SpellCastingAbility {
  display: flex;
  align-items: center;
}

.CharacterView .SpellStats .SpellAttackBonus {
  display: flex;
  align-items: center;
}

.CharacterView .SpellStats .SpellAttackBonus .Value {
  margin-left: 0.5em;
  margin-bottom: 0.5em;
  font-size: 2em;
  padding: 0;
}

.CharacterView .SpellStats .SpellSaveDC {
  display: flex;
  align-items: center;
}

.CharacterView .SpellStats .SpellSaveDC .Value {
  margin-left: 0.5em;
  font-size: 2em;
}
