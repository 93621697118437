.MapObject {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MapObject.Tiny {
  transform: scale(0.5);
}

.MapObject.Small {
  transform: scale(0.75);
}

.MapObject.Large {
  transform: scale(1.3);
}

.MapObject.Huge {
  transform: scale(1.65);
}

.MapObject.Gargantuan {
  transform: scale(2.1);
}

.MapObject .ant-badge-count {
  box-shadow: 0 0 0 1px lightgrey;
}

.ObjectButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1f1f1f;
  color: slategrey;
  font-weight: bold;
  outline: solid lightgrey 1px;
  border: solid rgb(133, 133, 133) 1px;
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  touch-action: manipulation;
}

.ObjectButton.Hidden {
  opacity: 0.325 !important;
  box-shadow: 0 0 0 3px gold;
}

.ObjectButton.Hidden:hover {
  opacity: 0.65 !important;
}

.ObjectButton:hover {
  background: #1f1f1f;
  outline: solid whitesmoke 1px;
  border: solid gold 1px;
  box-shadow: 0 0 0 2px rgb(155, 155, 155);
}

.ObjectButton:focus {
  background: #1f1f1f;
  border: solid gold 1px;
}

.ObjectButton:active {
  background: #1f1f1f;
  border: solid gold 1px;
}

.MapObjectPopover .ant-popover-inner {
  background: rgba(0, 0, 0, 0.5);
}

.MapObjectPopover .ant-popover-arrow {
  display: none;
}

.MapObjectPopover .PopoverContent {
  display: flex;
  color: whitesmoke;
}

.MapObjectPopover .PopoverContent .ObjectButton {
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2em;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .Title {
  font-size: 0.8em;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .Conditions {
  font-size: 0.7em;
  max-width: 250px;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .Stats {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .Stats svg {
  margin-right: 0.5em;
  font-size: 1.3em;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .Health {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 1em;
  margin-top: 0.25em;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .Icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .Values {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .Health .Values .Bottom {
  border-top: solid whitesmoke 1px;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .AC {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0.25em;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .AC .Values .Top {
  margin-top: 4px;
  margin-bottom: -4px;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .Buttons {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0.75em;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .Buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .Buttons button svg {
  font-size: 1.6em;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .Execute {
  width: 100%;
  margin-top: 0.75em;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .Execute button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MapObjectPopover .PopoverContent.ObjectPopoverContent .Execute button svg {
  margin-right: 0.5em;
  font-size: 1.4em;
}

.HealthModalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.HealthModalContent .ant-form-item {
  margin-bottom: 0;
}

.HealthModalContent .ant-form-item-label {
  width: 140px;
}

.HealthModalContent .ant-form-item-control {
  width: 140px;
}

.HealthModalContent .Spacer {
  margin: 0.25em;
}

.HealthModalContent button {
  margin-top: 0.75em;
}

.MapObject .StatusOverlay {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  animation: spin 8s linear infinite;
}

.MapObject .StatusOverlay .Status {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MapObject .StatusOverlay .StatusInner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MapObject .StatusOverlay .StatusInner svg {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  padding: 1px;
}

.ConditionMenuItem {
  display: flex;
  align-items: center;
}

.ConditionMenuItem svg {
  margin-left: -0.5em;
  font-size: 1.75em;
}

.MapObject .HealthStatusOverlay {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.MapObject .HealthStatusOverlay.Background {
  background: rgba(0, 0, 0, 0.5);
}

.NpcMapModalContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NpcMapModalContent .Row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NpcMapModalContent .Col {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NpcMapModalContent .RollButton {
  padding: 0.5em 0.25em;
  height: auto !important;
}

.NpcMapModalContent .RollButtonContent {
  width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NpcMapModalContent .RollButtonContent svg {
  font-size: 1.2em;
}

.NpcMapModalContent .OtherInfo {
  margin-top: 1em;
}

.MapObject .MinionOverlay {
  pointer-events: none;
  position: absolute;
  bottom: -6px;
  padding: 0;
  z-index: 9999;
}

.MapObject .MinionOverlay svg {
  border-radius: 50%;
  border: solid rgb(106, 52, 160) 1px;
  color: rgb(106, 52, 160);
  background: rgba(255, 255, 255, 0.5);
}
