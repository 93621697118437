.App {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.ant-tooltip-inner {
  background: rgba(0, 0, 0, 0.5);
}

.ant-tooltip-arrow {
  display: none;
}

.ant-dropdown-menu {
  background: rgba(0, 0, 0, 0.9);
}

.ant-btn:not(.ObjectButton):not(.DeleteButton):hover {
  color: rgb(70, 151, 226);
}

.ant-btn-circle.ant-btn-lg {
  min-width: 0;
}

.WideButton {
  width: 100%;
}

/* ::selection {
  background: rgb(70, 151, 226);
} */

.ant-checkbox-checked .ant-checkbox-inner {
  background: rgb(70, 151, 226);
  border-color: rgb(35, 64, 90);
}

.ant-menu-item-selected .ant-menu-title-content {
  color: rgb(70, 151, 226);
}

.ant-btn[disabled]:hover {
  color: rgba(255, 255, 255, 0.3);
}

.ModalButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ModalButtonWrapper .Message {
  margin-right: 2em;
  color: rgba(255, 255, 255, 0.45);
}
