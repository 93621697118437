.DMDrawerContent button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DMDrawerContent button svg {
  margin-right: 0.5em;
}

.DMDrawerContent .MapDescription {
  display: flex;
  flex-direction: column;
}

.DMDrawerContent .MapDescription .Selected {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.DMDrawerContent .MapDescription .Row {
  display: flex;
}

.DMDrawerContent .MapDescription button {
  flex-grow: 1;
}

.DMDrawerContent .MapSearch {
  margin-top: 1em;
  margin-bottom: 0.75em;
}

.MapModalContent {
  width: 100%;
  display: flex;
}

.MapModalContent .Image {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
  margin-right: 1em;
}

.MapModalContent .Image.NoImage:hover {
  border: solid rgb(70, 151, 226) 1px;
}

.MapModalContent .Image label {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.MapModalContent .Image img {
  max-width: 200px;
  max-height: 200px;
  border-radius: 2px;
}

.MapModalContent .Image img:hover {
  border: solid rgb(70, 151, 226) 1px;
}

.MapModalContent .Image.NoImage:hover {
  border: solid rgb(70, 151, 226) 1px;
}

.MapModalContent .Form {
  flex-grow: 1;
}

.MapModalContent .ant-form {
  height: 100%;
  width: 100%;
}

.MapModalContent .FormContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MapModalContent .ant-form-item-label {
  width: 62px;
}

.DMDrawerContent .NpcDescription {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.DMDrawerContent .NpcDescription .Row {
  width: 100%;
  display: flex;
}

.DMDrawerContent .NpcDescription button {
  flex-grow: 1;
}

.DMDrawerContent .ant-avatar {
  border: solid lightgrey 1px;
}

.DMDrawerContent .NpcSearch {
  margin-top: 1em;
  margin-bottom: 0.75em;
}

.DMDrawerContent .Objects .ObjectDescription {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.DMDrawerContent .Objects .ObjectDescription .Row {
  width: 100%;
  display: flex;
}

.DMDrawerContent .Objects .ObjectDescription button {
  flex-grow: 1;
}

.DMDrawerContent .Objects .ant-avatar {
  border: solid lightgrey 1px;
}

.DMDrawerContent .ObjectSearch {
  margin-top: 1em;
  margin-bottom: 0.75em;
}
