.CharacterView .SkillsAndSaves {
  width: 100%;
  display: flex;
  padding: 2em;
}

.CharacterView .SkillsAndSaves .Heading {
  font-size: 1.25em;
  margin-bottom: 0.5em;
}

.CharacterView .SkillsAndSaves .SkillRow {
  width: 222px;
  display: flex;
  align-items: center;
}

.CharacterView .SkillsAndSaves .SkillRow .ant-row {
  margin-bottom: 0;
}

.CharacterView .SkillsAndSaves .SkillRow .Bonus {
  width: 24px;
  font-size: 1.25em;
  margin-left: 8px;
  text-align: center;
}

.CharacterView .SkillsAndSaves .SkillRow .Label {
  margin-left: 12px;
}

.CharacterView .SkillsAndSaves .SkillsSection {
  display: flex;
  flex-wrap: wrap;
}
