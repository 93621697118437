.TopButtons {
  width: 100%;
  height: 100%;
  display: flex;
}

.TopButtons .ant-menu {
  width: 100%;
  height: 100%;
  background: none;
  line-height: 32px;
  flex-grow: 1;
}

.TopButtons .ant-menu .ant-menu-item {
  height: 100%;
  flex-grow: 1;
  max-width: 24%;
  display: flex;
  justify-content: center;
}

.TopButtons .ant-menu .ant-menu-item svg {
  font-size: 1.6em;
}
