.CharacterSelect {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 1em;
  animation: fadein 0.25s ease-out;
}

.CharacterSelect .List {
  width: 420px;
}

.CharacterSelect button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 0.25em;
}

.CharacterSelect button:not(.DeleteButton) svg {
  font-size: 1.1em;
  margin-right: 0.5em;
}

.CharacterSelect .CharacterButtonContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CharacterSelect .Col {
  display: flex;
  flex-direction: column;
}

.CharacterSelect .Row {
  display: flex;
  align-items: center;
}

.CharacterSelect .RowItem {
  margin: 0.15em;
  line-height: 12px;
  margin-bottom: 0.5em;
}

.CharacterSelect .SelectButton {
  flex-grow: 1;
  margin: 0;
}

.CharacterSelect .DeleteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
