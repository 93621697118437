.MapGridAdjust {
  position: relative;
}

.MapGridAdjust .AdjustOverlay {
  position: absolute;
  top: 20vh;
  left: calc(40vw - 140px);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  padding: 0.5em;
}

.MapGridAdjust .AdjustOverlay .Heading {
  width: 100%;
  margin-top: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.MapGridAdjust .AdjustOverlay .Row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25em;
}

.MapGridAdjust .AdjustOverlay .Col {
  min-width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MapGridAdjust .AdjustOverlay .Center {
  padding: 2em;
}

.MapGridAdjust .AdjustOverlay .Zoom {
  padding: 1em;
}

.MapGridAdjust .AdjustOverlay button {
  background: rgba(0, 0, 0, 0.5);
}

.MapGridAdjust .AdjustOverlay .Footer {
  padding-top: 1.5em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.MapGridAdjust .AdjustOverlay .Footer button {
  width: 100%;
}

.MapGridAdjust .AdjustCell {
  border: solid rgba(255, 255, 0, 0.5) 1px;
  transition: all 0.15s linear;
}
