.Map .Toolbar {
  position: absolute;
  top: 0;
  right: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: calc(100vh - 32px);
  overflow: hidden;
}

.Map .Toolbar .ToolbarSection {
  display: flex;
  flex-direction: column;
}

.Map .Toolbar button {
  height: 36px;
  width: 36px;
  padding: 1px;
  background: rgba(0, 0, 0, 0.5);
  animation: fadein 0.35s;
}

.Map .Toolbar button:hover {
  background: rgba(0, 0, 0, 0.9);
}

.Map .Toolbar button svg {
  font-size: 32px !important;
  line-height: 32px;
}
