.CharacterView .Feats {
  padding: 1em;
}

.CharacterView .Feats .FeatsList .ant-collapse-header {
  display: flex;
  align-items: center;
}

.CharacterView .Feats .FeatsList .FeatHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.CharacterView .Feats .FeatsList .FeatHeader .FeatName {
  width: 300px;
  margin-right: 1em;
  text-align: center;
}

.CharacterView .Feats .FeatsList .FeatHeader button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.CharacterView .Feats .FeatsList .FeatHeader button svg {
  font-size: 1.2em;
  margin-right: 0.5em;
}

.CharacterView .Feats .FeatsList .FeatDescription {
  margin-bottom: 0.75em;
  white-space: pre-line;
}

.CharacterView .Feats .FeatModifier {
  display: flex;
}

.CharacterView .Feats .FeatModifier .Operation {
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.CharacterView .Feats .Empty {
  width: 100%;
  padding: 1em;
  font-style: italic;
  text-align: center;
}

.CharacterView .Feats .ButtonWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CharacterView .Feats .NewButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  margin-top: 0.25em;
}

.CharacterView .Feats .NewButton svg {
  margin-right: 0.5em;
  font-size: 1.25em;
}

.FeatsModalContent .ant-row {
  margin-bottom: 1em;
}

.FeatsModalContent .ant-form-item-label {
  width: 110px;
}

.FeatsModalContent .Modifier {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FeatsModalContent .Modifiers button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.FeatsModalContent .Modifiers .AddButton svg {
  margin-right: 0.5em;
}
