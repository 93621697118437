.Map .ant-drawer-body {
  padding: 12px;
}

.Map .DrawerContent .ant-collapse-header {
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.Map .DrawerContent .PanelHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Map .DrawerContent .PanelHeader button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.Map .DrawerContent .PanelHeader button svg {
  font-size: 1.2em;
  margin-right: 0.5em;
}

.Map .DrawerContent .Rolls .PanelHeader {
  padding-top: 8px;
  padding-bottom: 8px;
}

.Map .DrawerContent .Rolls .ant-collapse-content-box {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.Map .DrawerContent .Rolls .ant-collapse-content-box button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Map .DrawerContent .Rolls .ant-collapse-content-box button svg {
  margin-right: 0.5em;
  font-size: 1.5em;
}

.Map .DrawerContent .SpellAttackBonus {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -0.25em;
}

.Map .DrawerContent .SpellAttackBonus .Value {
  font-size: 1.5em;
  margin-left: 0.5em;
  margin-bottom: 0.25em;
  padding: 0;
}

.Map .DrawerContent .SpellSaveDC {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.25em;
}

.Map .DrawerContent .SpellSaveDC .Value {
  font-size: 1.5em;
  margin-left: 0.5em;
}

.Map .DrawerContent .Resource {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Map .DrawerContent .Resource .Values {
  display: flex;
  align-items: center;
}

.Map .DrawerContent .Resource .Values .Current {
  margin-right: 1em;
}

.Map .DrawerContent .Currencies {
  padding-top: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Map .DrawerContent .Currencies .ant-input-number {
  width: 120px;
}

.Map .DrawerContent .Currency .ant-form-item {
  margin-bottom: 1em;
}

.Map .DrawerContent .Currency .ant-form-item-label {
  width: 60px;
}

.Map .DrawerContent .Minions button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Map .DrawerContent .Minions button svg {
  margin-right: 0.5em;
}

.Map .DrawerContent .Minions .MinionSearch {
  margin-top: 1em;
  margin-bottom: 0.75em;
}

.Map .DrawerContent .TurnOrder .Controls {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.Map .DrawerContent .TurnOrder .Controls button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Map .DrawerContent .TurnOrder .Controls button svg {
  margin-right: 0.5em;
  font-size: 1.1em;
}

.Map .DrawerContent .TurnOrder .NoTurnOrder {
  padding: 1em;
}

.Map .DrawerContent .TurnOrder .TurnOrderList {
  padding-top: 0.75em;
}

.Map .DrawerContent .TurnOrder .Item {
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1.05em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Map .DrawerContent .TurnOrder .Item .Name {
  display: flex;
  align-items: center;
}

.Map .DrawerContent .TurnOrder .Item .Roll {
  font-size: 1.5em;
}

.Map .DrawerContent .TurnOrder .Item .Name button {
  margin-right: 0.5em;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TurnOrderModalContent .ant-form-item-label {
  width: 124px;
}

.Map .DrawerContent .Minions .MinionDescription {
  width: 100%;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
}

.Map .DrawerContent .Minions .MinionDescription .Row {
  width: 100%;
  display: flex;
}

.Map .DrawerContent .Minions .ant-avatar {
  border: solid lightgrey 1px;
}

.Map .DrawerContent .PanelContent div {
  white-space: pre-line;
}
