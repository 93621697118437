.CampaignSelect {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
}

.CampaignSelect .DNDIcon {
  margin-top: 5vh;
  font-size: 200px;
  height: 260px;
}

.CampaignSelect .CampaignButton {
  height: auto;
  margin: 0.5em;
  width: 300px;
}

.CampaignSelect .LogOutButton {
  margin: 1em;
}
