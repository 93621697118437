.Journal {
  width: 100%;
  max-height: calc(100vh - 32px);
  display: flex;
  justify-content: center;
  overflow: hidden;
  animation: fadein 0.25s ease-out;
}

.Journal .JournalContent {
  max-width: 1200px;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.Journal .Entries {
  display: flex;
  flex-direction: column;
  background: #131313;
  /* padding: 2em 1em; */
  min-width: 203px;
}

.Journal .Entries h2 {
  margin-bottom: 0.75em;
  padding: 0.5em 1em 0 1em;
}

.Journal .Entries .ant-menu {
  max-width: 250px;
}

.Journal .Entry {
  flex-grow: 1;
  padding: 2em;
}

.Journal .Entry .ant-form-item-label {
  width: 90px;
}

.Journal .Entry .DeleteButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Journal button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Journal .DeleteButton {
  border: none;
  margin-top: -0.5em;
}

.Journal .DeleteButton:hover {
  color: rgba(253, 44, 44, 0.774);
}

.Journal button svg {
  margin-right: 0.5em;
  font-size: 1.2em;
}
