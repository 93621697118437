@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    /* background: black; */
  }
  to {
    opacity: 1;
    /* background: none; */
  }
}

@keyframes fadeshrinkin {
  from {
    transform: scale(3) translate(-50px, 50px);
    opacity: 0;
  }
  to {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
}

@keyframes blurin {
  from {
    filter: blur(5px);
  }
  to {
    filter: blur(0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinback {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
