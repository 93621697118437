.MainLayout {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.MainLayout .TopBar {
  width: 100%;
  height: 32px;
  display: flex;
  border-bottom: 1px solid grey;
}

.MainLayout .Page {
  width: 100%;
  height: calc(100vh - 32px);
  display: flex;
  flex-grow: 1;
}

.MainLayout .Page .Left {
  width: 80%;
  height: calc(100vh - 32px);
  display: flex;
  flex-grow: 1;
}

.MainLayout .Page .LeftNoChat {
  width: 100%;
  height: calc(100vh - 32px);
  display: flex;
  flex-grow: 1;
}

.MainLayout .Page .Right {
  min-width: 280px;
  height: calc(100vh - 32px);
  width: 20%;
  display: flex;
  flex-grow: 1;
  border-left: 1px solid grey;
}

.MainLayout .Page .RightHidden {
  height: calc(100vh - 32px);
  width: 0;
  display: flex;
  flex-grow: 1;
  border-left: 1px solid grey;
}
