.Chat {
  position: relative;
  width: 100%;
  height: calc(100vh - 32px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.Chat .Messages {
  max-width: 100%;
  height: calc(100vh - 32px - 40px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid grey;
  margin: 2px;
  padding: 0.35em;
  overflow: auto;
  font-size: 0.9em;
  background: #131313;
  transition: all 0.35s;
}

.Chat .Messages .MessageContent {
  width: 100%;
  position: relative;
  padding-bottom: 1.5em;
}

.Chat .Messages .Sender {
  padding-right: 10px;
  font-weight: bold;
  text-align: center;
  color: #177ddc;
}

.Chat .Messages .ant-list-item {
  padding: 0;
  transition: all 0.35s;
}

.Chat .Messages .Message .Text1 {
  display: flex;
  margin-top: 1em;
}

.Chat .Messages .Message .Text2 {
  margin-top: 0.35em;
  margin-bottom: 1em;
}

.Chat .Messages .Gif .Text1 {
  display: flex;
  margin-top: 1em;
}

.Chat .Messages .Gif .Text2 {
  width: 100%;
  margin-top: 0.35em;
  margin-bottom: 1em;
  justify-content: center;
  text-align: center;
  display: flex;
}

.Chat .Messages .ShareItem {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
}

.Chat .Messages .ShareItem .Text1 {
  display: flex;
  margin-bottom: 0.5em;
}

.Chat .Messages .ShareItem .Text2 {
  font-size: 1.5em;
  text-align: center;
}

.Chat .Messages .ShareItem .Text3 {
  margin-top: 0.5em;
  font-size: 1.1em;
  text-align: center;
}

.Chat .Messages .ShareItem .Text4 {
  /* width: 100%; */
  margin-top: 0.5em;
  /* justify-content: center; */
  text-align: center;
  display: flex;
  flex-direction: column;
}

.Chat .Messages .ShareItem .Text4 .Modifier {
  width: 100%;
  margin-top: 0.5em;
  justify-content: center;
  text-align: center;
  display: flex;
}

.Chat .Messages .ShareItem .Text4 .Operation {
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.Chat .Messages .ShareFeat {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
}

.Chat .Messages .ShareFeat .Text1 {
  display: flex;
  margin-bottom: 0.5em;
}

.Chat .Messages .ShareFeat .Text2 {
  font-size: 1.5em;
  text-align: center;
}

.Chat .Messages .ShareFeat .Text3 {
  margin-top: 0.5em;
  font-size: 1.1em;
  text-align: center;
}

.Chat .Messages .ShareFeat .Text4 {
  /* width: 100%; */
  margin-top: 0.5em;
  /* justify-content: center; */
  text-align: center;
  display: flex;
  flex-direction: column;
}

.Chat .Messages .ShareFeat .Text4 .Modifier {
  width: 100%;
  margin-top: 0.5em;
  justify-content: center;
  text-align: center;
  display: flex;
}

.Chat .Messages .ShareFeat .Text4 .Operation {
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.Chat .Messages .Spell {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
}

.Chat .Messages .Spell .Text1 {
  display: flex;
  margin-bottom: 0.5em;
}

.Chat .Messages .Spell .Text2 {
  font-size: 1.25em;
  text-align: center;
  margin-bottom: 0.5em;
}

.Chat .Messages .Spell .Text3 {
  text-align: center;
}

.Chat .Messages .Spell .Text4 {
  text-align: center;
}

.Chat .Messages .Spell .Text5 {
  text-align: center;
}

.Chat .Messages .Spell .Text6 {
  text-align: center;
}

.Chat .Messages .Spell .Text7 {
  text-align: center;
  margin-top: 0.75em;
}

.Chat .Messages .Damage {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
}

.Chat .Messages .Damage .Text1 {
  display: flex;
  margin-bottom: 0.5em;
}

.Chat .Messages .Damage .Text2 {
  font-size: 1.25em;
  text-align: center;
  margin-bottom: 0.5em;
}

.Chat .Messages .Damage .Text3 {
  font-size: 1.5em;
  text-align: center;
}

.Chat .Messages .Damage .Text4 {
  font-size: 2.5em;
  text-align: center;
}

.Chat .Messages .Damage .Text5 {
  font-size: 1.25em;
  text-align: center;
}

.Chat .Messages .ShareAttack {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
}

.Chat .Messages .ShareAttack .Text1 {
  display: flex;
  margin-bottom: 0.5em;
}

.Chat .Messages .ShareAttack .Text2 {
  font-size: 1.5em;
  text-align: center;
}

.Chat .Messages .ShareAttack .Text3 {
  font-size: 1.25em;
  text-align: center;
}

.Chat .Messages .ShareAttack .Text4 {
  margin-top: 0.5em;
  font-size: 1.1em;
  text-align: center;
}

.Chat .Messages .Attack {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
}

.Chat .Messages .Attack .Text1 {
  display: flex;
  margin-bottom: 0.5em;
}

.Chat .Messages .Attack .Text2 {
  font-size: 1.5em;
  text-align: center;
}

.Chat .Messages .Attack .Text3 {
  font-size: 1.5em;
  text-align: center;
}

.Chat .Messages .Attack .Text4 {
  font-size: 2.5em;
  text-align: center;
}

.Chat .Messages .Attack .Text5 {
  text-align: center;
}

.Chat .Messages .Stat {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
}

.Chat .Messages .Stat .Text1 {
  display: flex;
}

.Chat .Messages .Stat .Text2 {
  font-size: 1.5em;
  text-align: center;
}

.Chat .Messages .Stat .Text3 {
  font-size: 2.5em;
  text-align: center;
}

.Chat .Messages .Message .Text {
  word-wrap: break-word;
}

.Chat .Messages .Roll {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
}

.Chat .Messages .Roll .Text1 {
  display: flex;
}

.Chat .Messages .Roll .Text2 {
  font-size: 1.5em;
  text-align: center;
}

.Chat .Messages .Roll .Text3 {
  font-size: 2.5em;
  text-align: center;
}

.Chat .Messages .Message .Text {
  word-wrap: break-word;
  white-space: pre-line;
}

.Chat .Controls {
  height: 40px;
}

.Chat .NewMessage {
  height: 40px;
}

.Chat .SendButton {
  padding: 0;
  margin-right: -8px;
  max-width: 30px;
  max-height: 30px;
  line-height: 10px;
  font-size: 24px;
  color: grey;
  background: none;
  opacity: 0.5;
}

.Chat .HideButton {
  position: absolute;
  bottom: 2px;
  left: -35px;
}

.DetailsModalContent .Info {
  margin-bottom: 0.5em;
}

.DetailsModalContent .Description {
  white-space: pre-line;
}

.Chat .Messages .ReactionOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.Chat .Messages .ReactionOverlay .AddReactionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  height: auto;
  width: auto;
  margin: 4px;
}

.Chat .Messages .ReactionOverlay .AddReactionButton svg {
  font-size: 20px;
}

.Chat .Messages .ReactionOverlay .EmojiButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  height: auto;
  width: auto;
  margin: 4px;
  animation: fadein 0.35s;
}

.Chat .Messages .ReactionOverlay .ant-badge-count {
  pointer-events: none;
  min-width: 12px;
  height: 12px;
  line-height: 12px;
  background: rgb(35, 64, 90);
}

.ReactionPopover .ant-popover-inner {
  border-radius: 5px;
  background: #1f1f1f;
  animation: fadein 0.35s;
}

.ReactionPopover .ant-popover-inner-content {
  padding: 0;
  height: 356px;
  border-radius: 5px;
}

.ReactionTooltip .ant-tooltip-inner {
  background: #1f1f1f;
}

.Chat .LinkedImage {
  margin-top: 0.25em;
  max-width: 100%;
  border-radius: 4px;
}

.Chat .Buttons {
  display: flex;
  align-items: center;
}

.Chat .Buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Chat .Buttons button svg {
  font-size: 1.5em;
}

.GiphyModalContent .reactGiphySearchbox-componentWrapper {
  width: auto !important;
}

.GiphyModalContent input {
  max-width: 440px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: transparent;
  background-image: none;
  border: 1px solid #434343;
  border-radius: 2px;
  transition: all 0.3s;
}

.GiphyModalContent input:hover {
  border-color: #165996;
  border-right-width: 1px !important;
}
