.Map {
  width: 100%;
  display: flex;
  position: relative;
  max-height: calc(100vh - 32px);
  overflow: hidden;
  animation: fadein 0.25s ease-out;
}

.Map .MainSection {
  flex-grow: 1;
  max-height: calc(100vh - 32px);
}

.Map .CharacterOverlay {
  position: absolute;
  top: 0;
  left: calc(40vw - 175px);
}

.Map .DropOverlay {
  position: absolute;
  top: 0;
  left: calc(40vw - 225px);
}

.Map .CharacterOverlayContent {
  padding: 0.5em 1em;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadein 0.35s ease;
}

.Map .CharacterOverlayContent h2 {
  margin: 0.25em 0;
}

.Map .CharacterOverlayContent button {
  background: rgba(0, 0, 0, 0.4);
}

.Map .PreviewOverlay {
  padding: 0.5em 1em;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadein 0.35s ease;
  position: absolute;
  top: 0;
  left: calc(40vw - 175px);
}

.Map .PreviewOverlay button {
  background: rgba(0, 0, 0, 0.4);
}

.Map .DMOverlay {
  padding: 0.5em 1em;
  border-radius: 2px;
  text-shadow: 1px 1px #1f1f1f, -1px -1px #1f1f1f, -1px 1px #1f1f1f, 1px -1px #1f1f1f;
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeshrinkin 0.35s ease;
  position: absolute;
  top: 0;
  right: 70px;
}
