.CharacterView .Attacks {
  padding: 1em;
}

.CharacterView .Attacks .AttacksList .ant-collapse-header {
  display: flex;
  align-items: center;
}

.CharacterView .Attacks .AttacksList .AttackHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.CharacterView .Attacks .AttacksList .AttackHeader .AttackName {
  width: 170px;
  margin-right: 1em;
  text-align: center;
}

.CharacterView .Attacks .AttacksList .AttackHeader .AttackBonus {
  min-width: 60px;
  margin-right: 1em;
  font-size: 1.25em;
  text-align: center;
}

.CharacterView .Attacks .AttacksList .AttackHeader .AttackDamage {
  min-width: 111px;
  margin-right: 1em;
  text-align: center;
}

.CharacterView .Attacks .AttacksList .AttackHeader .AttackDamageType {
  min-width: 90px;
  margin-right: 1em;
  text-align: center;
}

.CharacterView .Attacks .AttacksList .AttackHeader button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.CharacterView .Attacks .AttacksList .AttackHeader button svg {
  font-size: 1.2em;
  margin-right: 0.5em;
}

.CharacterView .Attacks .AttacksList .AttackDescription {
  margin-bottom: 0.75em;
  white-space: pre-line;
}

.CharacterView .Attacks .Empty {
  width: 100%;
  padding: 1em;
  font-style: italic;
  text-align: center;
}

.CharacterView .Attacks .ButtonWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CharacterView .Attacks .NewButton {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  margin-top: 0.25em;
}

.CharacterView .Attacks .NewButton svg {
  margin-right: 0.5em;
  font-size: 1.25em;
}

.AttackModalContent .ant-row {
  margin-bottom: 1em;
}

.AttackModalContent .ant-form-item-label {
  width: 110px;
}
