.MapGrid .Cell .InitialOverlay {
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  animation: fadeout 0.25s linear;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 999;
  pointer-events: none;
}

.MapGrid .Cell:not(.Dropping):hover {
  border: dashed lightgrey 1px;
}

.MapGrid .Cell.Dropping:hover {
  border: dashed gold 1px;
  background: rgba(255, 217, 0, 0.5);
}

.MapGrid .Cell .GridOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  pointer-events: none;
  border-left: dashed rgb(136, 136, 136) 1px;
  border-bottom: dashed rgb(136, 136, 136) 1px;
}

.MapGrid .Cell .GridOverlayInner {
  width: 100%;
  height: 100%;
  border-right: dashed rgb(44, 44, 44) 1px;
  border-top: dashed rgb(44, 44, 44) 1px;
  pointer-events: none;
}

.MapGrid .Cell .DragOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  pointer-events: none;
  border: dashed lightgrey 2px;
}

.MapGrid .Cell .DragDistanceOverlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: -32px;
  z-index: 999;
  padding: 4px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
  pointer-events: none;
  color: white;
  background: rgba(0, 0, 0, 0.5);
}

.MapGrid .Cell.Dragging {
  background: white;
}

.MapGrid .Cell.Fogged {
  background: black;
}

/* .MapGrid .Cell.Fogged div {
  display: none;
} */

.MapGrid .Black {
  background: black;
}
