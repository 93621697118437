.PageSection {
  width: 100%;
  height: 100%;
  display: flex;
  background: #090909;
}

.PageSection .Page {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
}
