.Campaign {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  animation: fadein 0.25s ease-out;
}

.Campaign .DNDIcon {
  font-size: 200px;
  height: 260px;
}

.Campaign .Lists {
  display: flex;
}

.Campaign .Lists .Col {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 3em;
}
