.Knowledge {
  width: 100%;
  max-height: calc(100vh - 32px);
  display: flex;
  justify-content: center;
  overflow: hidden;
  animation: fadein 0.25s ease-out;
}

.Knowledge .KnowledgeContent {
  max-width: 1200px;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.Knowledge .NoEntries {
  margin-top: 1em;
  font-style: italic;
  text-align: center;
}

.Knowledge .Entries {
  display: flex;
  flex-direction: column;
  background: #131313;
  /* padding: 2em 1em; */
  min-width: 203px;
}

.Knowledge .Entries h2 {
  margin-bottom: 0.75em;
  padding: 0.5em 1em 0 1em;
}

.Knowledge .Entries .ant-menu {
  max-width: 250px;
}

.Knowledge .NoSelected {
  width: 100%;
  text-align: center;
}

.Knowledge .NoSelectedMessage {
  font-style: italic;
}

.Knowledge .Entry {
  flex-grow: 1;
  padding: 2em;
}

.Knowledge .EntryName {
  text-align: center;
}

.Knowledge .EntryText {
  max-width: 800px;
  padding-top: 0.5em;
  margin: auto;
  white-space: pre-line;
}

.Knowledge .ImageFileSpacer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5em;
  padding: 0.5em;
}

.Knowledge .Entry .ant-form-item-label {
  width: 90px;
}

.Knowledge .Entry .DeleteButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Knowledge button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Knowledge .DeleteButton {
  border: none;
  margin-top: -0.5em;
}

.Knowledge .DeleteButton:hover {
  color: rgba(253, 44, 44, 0.774);
}

.Knowledge button svg {
  margin-right: 0.5em;
  font-size: 1.2em;
}

.Knowledge .FilesWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Knowledge .ImageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5em;
}

.Knowledge .KnowledgeImage {
  width: 200px;
  height: 200px;
  margin: 0.5em;
}

.Knowledge img {
  max-width: 200px;
  max-height: 200px;
  border: solid lightgrey 1px;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Knowledge .KnowledgeImage img:hover {
  border: solid rgb(70, 151, 226) 1px;
}

.Knowledge .KnowledgeImageUpload {
  cursor: pointer;
  width: 200px;
  height: 200px;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Knowledge .KnowledgeImageUpload:not(.ImageUploaded) {
  border: dashed rgba(211, 211, 211, 0.5) 2px;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Knowledge .KnowledgeImageUpload:not(.ImageUploaded):hover {
  border: dashed rgb(70, 151, 226) 2px;
}

.Knowledge .KnowledgeImageUpload label {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
