.Map .DMToolbar {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
}

.Map .DMToolbar .ToolbarSection {
  display: flex;
}

.Map .DMToolbar button {
  height: 36px;
  width: 36px;
  padding: 1px;
  background: rgba(0, 0, 0, 0.5);
  animation: fadein 0.35s;
}

.Map .Toolbar button:hover {
  background: rgba(0, 0, 0, 0.9);
}

.Map .DMToolbar button svg {
  font-size: 32px !important;
  line-height: 32px;
}
