.Invite {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Invite button {
  margin: 1em;
}
