.SimpleNPCFormContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.SimpleNPCFormContent .ModalButtonWrapper {
  margin-top: 1em;
}

.SimpleNPCFormContent .Row {
  width: 100%;
  display: flex;
  justify-content: center;
}

.SimpleNPCFormContent .Image {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 2px;
  margin-right: 1em;
}

.SimpleNPCFormContent .Image.NoImage:hover {
  border: solid rgb(70, 151, 226) 1px;
}

.SimpleNPCFormContent .Image label {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.SimpleNPCFormContent .Image img {
  max-width: 200px;
  max-height: 200px;
  border-radius: 2px;
}

.SimpleNPCFormContent .Image img:hover {
  border: solid rgb(70, 151, 226) 1px;
}

.SimpleNPCFormContent .Image.NoImage:hover {
  border: solid rgb(70, 151, 226) 1px;
}

.SimpleNPCFormContent .Form {
  flex-grow: 1;
}

.SimpleNPCFormContent .ant-form {
  height: 100%;
  width: 100%;
}

.SimpleNPCFormContent .FormContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SimpleNPCFormContent .ant-form-item-label {
  width: 90px;
}

.SimpleNPCFormContent .Row.FullWidth .ant-form-item {
  width: 100%;
  padding-right: 12px;
  margin-bottom: 0;
  flex-direction: column;
}

.SimpleNPCFormContent .Row.FullWidth .ant-form-item-label {
  width: 130px;
}

.SimpleNPCFormContent .Row.FullWidth .ant-form-item-control {
  width: 100%;
}

.SimpleNPCFormContent .ant-collapse-item .ant-collapse-header {
  padding: 4px 16px;
}

.SimpleNPCFormContent .SmallInput {
  width: 90px;
}
