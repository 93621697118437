.MapGrid {
  position: relative;
  overflow: overlay;
}

.MapGrid.Frozen {
  filter: saturate(0);
}

.MapGrid.Frozen.NoMove .ObjectButton {
  pointer-events: none;
}

.MapGrid .Cell {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NoMap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
